export default function ajaxForm() {
  // Ajax Expresss Form
  var safari = navigator.userAgent.indexOf('Safari') > -1

  function ajaxifyForm(event) {
    var form = event.target
    var data = new FormData(form)
    var request = new XMLHttpRequest()

    form.querySelector('button[type=submit]').setAttribute('disabled', 'disabled')

    if (safari) {
      for (var i = 0; i < form.elements.length; i++) {
        if (form.elements[i].type === 'file') {
          if (form.elements[i].value === '') {
            var elem = form.elements[i]
            data.delete(elem.name)
          }
        }
      }
    }

    var method = form.getAttribute('method')
    var action = form.getAttribute('action')

    request.open(method, action ? action : window.location.href, true)
    request.setRequestHeader('Cache-Control', 'no-cache')
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    request.setRequestHeader('HTTP_X_REQUESTED_WITH', 'XMLHttpRequest')
    request.onload = function () {
      removeMessages(form)

      if (request.status === 200) {
        var response = JSON.parse(request.response)
        // console.log(response);
        if (response.success) {
          // Reset the form so that the user may enter fresh information
          form.reset()

          // ============================================================
          // Uncomment this to have the form redirect to the success page
          // ============================================================
          // if (response.return) {
          //   window.location.href = response.return;
          // }

          renderFormSuccess(form)

        } else if (response.errors || response.formErrors) {
          renderErrors(response.errors, form)
          renderFormErrors(response.formErrors, form)
        }

        replaceInputs(form, response);
        unlockSubmit(form)
      } else {
        console.error(request)
      }

      unlockSubmit(form)
    }

    request.send(data)
    event.preventDefault()
  }

  function replaceInputs(form, response) {
    if (response.duplicateCheck) {
      var prefix = response.duplicateCheck.prefix;
      var value = response.duplicateCheck.value;
      var input = form.querySelector('input[name^="' + prefix + '"]');
      if (input) {
        input.setAttribute('name', value);
        input.setAttribute('value', value);
      }
    }
  }

  /**
   * Remove the "disabled" state of the submit button upon successful submit
   *
   * @property form
   */
  function unlockSubmit(form) {
    form.querySelector('button[type=submit]').removeAttribute('disabled')
    // if (typeof grecaptcha !== 'undefined') {
    //   grecaptcha.reset()
    // }
  }

  function removeMessages(form) {
    // Remove error messages
    form.querySelectorAll('ul.errors').remove()
    var fieldsWithErrors = form.querySelectorAll('.has-error')
    for (var fieldIndex = 0; fieldIndex < fieldsWithErrors.length; fieldIndex++) {
      fieldsWithErrors[fieldIndex].classList.remove('has-error')
    }

    // Remove success messages
    form.querySelectorAll('.form-success').remove()
    document.getElementsByClassName('form-errors').remove()
  }

  function renderFormSuccess(form) {
    var successMessage = document.createElement('h2')
    successMessage.classList.add('alert', 'alert-success', 'form-success')

    successMessage.appendChild(
      document.createTextNode('Thank you for your interest in working with TIEK BYDAY! A member of our team will follow up to discuss further.')
    )

    // form.insertBefore(successMessage, form.childNodes[0])
    form.innerHTML = '';
    form.appendChild(successMessage);
  }

  function renderErrors(errors, form) {
    for (var key in errors) {
      var messages = errors[key]
      // console.log(messages);
      var errorsList = document.createElement('ul')
      errorsList.classList.add('errors')

      for (var messageIndex = 0; messageIndex < messages.length; messageIndex++) {
        var message = messages[messageIndex]
        var listItem = document.createElement('li')
        listItem.appendChild(document.createTextNode(message))
        errorsList.appendChild(listItem)
      }

      var inputList = form.querySelectorAll('*[name=' + key + '], *[name=\'' + key + '[]\']')
      for (var inputIndex = 0; inputIndex < inputList.length; inputIndex++) {
        var input = inputList[inputIndex]
        // console.log(input);
        input.parentNode.classList.add('has-error')
        input.parentNode.appendChild(errorsList)
      }
    }
  }

  function renderFormErrors(errors, form) {
    var errorBlock = document.createElement('div')
    errorBlock.classList.add('alert', 'alert-danger', 'form-errors')

    errorBlock.appendChild(
      document.createTextNode('Error! Please review the form and try submitting again.')
    )

    if (errors.length) {
      var errorsList = document.createElement('ul')
      for (var messageIndex = 0; messageIndex < errors.length; messageIndex++) {
        var message = errors[messageIndex]
        var listItem = document.createElement('li')

        listItem.appendChild(document.createTextNode(message))
        errorsList.appendChild(listItem)
      }

      errorBlock.appendChild(errorsList)
    }

    form.insertBefore(errorBlock, form.childNodes[0])
  }

  // Add remove prototypes
  Element.prototype.remove = function () {
    this.parentElement.removeChild(this)
  }

  Element.prototype.findAncestor = function (className) {
    let el = this
    while (!el.classList.contains(className)) {
      el = el.parentElement
    }

    return el
  }

  NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
    for (var i = this.length - 1; i >= 0; i--) {
      if (this[i] && this[i].parentElement) {
        this[i].parentElement.removeChild(this[i])
      }
    }
  }

  document.getElementById('ajax-form').addEventListener('submit', ajaxifyForm)
}
