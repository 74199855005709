// The code snippet defines a function called "Nav" that handles the behavior of a navigation menu. It adds functionality to the NodeList prototype to support the forEach method. It also sets up event listeners and animations for toggling the menu, opening and closing sub
export default function Nav() {

  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (var i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }

  const nav = document.querySelectorAll(`Nav`)[0];
  const navToggleButton = document.getElementsByClassName(`Nav__toggle`)[0];
  // const navLinks = nav.querySelectorAll(`a`);
  // const navMenu = document.querySelector(`.Nav__menu`);

  const navToggleHome = document.querySelector(`.homepage .Nav__toggle`);
  const homeHeader = document.querySelector(`.homepage .Header`);

  const menuButtons = Array.from(document.querySelectorAll(`.Nav__menu-item > button`));
  const secondaryPanes = Array.from(document.querySelectorAll(`.Nav__secondary`));
  const closeButtons = Array.from(document.querySelectorAll(`.Nav__close`));

  const navHoverLinks = Array.from(document.querySelectorAll(`.Nav__secondary-content .Nav__links a`));
  const navFilters = document.querySelector(`.Nav__filters`);
  const navFiltersDrop = document.querySelector(`.Nav__filters-drop`);

  const navFilterableEls = Array.from(document.querySelectorAll(`.Nav__links--filterable li`));

  const focusableEls = Array.from(nav.querySelectorAll(`iframe, iframe *, [tabindex="0"], a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])`));
  const firstFocusableEl = focusableEls[0];
  const lastFocusableEl = focusableEls[focusableEls.length - 1];

  let curState = false;
  let isHeaderLight = false;

  let initialOpen = false;

  let windowSize = checkWindowSize();
  function checkWindowSize() {
    return window.innerWidth < 768 ? `small` : `large`;
  }

  function keyHandler(e) {


    let isEscape = (e.key === `Escape` || e.key === `Esc` || e.keyCode === 27);

    if (e.shiftKey) /* shift + tab */ {
      if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
        e.preventDefault();
      }
    } else /* tab */ {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus();
        e.preventDefault();
      }
    }

    if (isEscape && curState) {
      toggleNav();
    }

  }

  const toggleNav = function () {
    // console.log('size', windowSize);
    curState = !curState
    const getCurUrl = window.location.pathname.split('/')[1].toLowerCase();
    const speed = checkWindowSize() === `large` ? 1000 : 10;
    if (curState) {
      document.querySelector(`body`).classList.add(`no-scroll`);
      nav.classList.toggle(`Nav--is-active`);
      nav.addEventListener(`keydown`, keyHandler);

      setTimeout(() => {
        nav.classList.toggle(`Nav--visible`);
      }, 10);
      focusableEls[1].focus();
      isHeaderLight = document.body.classList.contains(`Body--light`);
      if (isHeaderLight) {
        document.body.classList.remove(`Body--light`);
      }

      if(getCurUrl  === `projects`){
        let curMenuButtonIndex = menuButtons.findIndex(el=>el.textContent.toLowerCase() === 'portfolio');
        setTimeout(() => {
          menuButtons[curMenuButtonIndex].click();
        }, speed);
      } else if(getCurUrl  === `journal`){
        let curMenuButtonIndex = menuButtons.findIndex(el=>el.textContent.toLowerCase() === 'journal');
        setTimeout(() => {
          menuButtons[curMenuButtonIndex].click();
        }, speed);
      } else if(getCurUrl  === `about` || getCurUrl  === `services` || getCurUrl  === `press` || getCurUrl  === `careers` || getCurUrl  === `our-approach`){
        let curMenuButtonIndex = menuButtons.findIndex(el=>el.textContent.toLowerCase() === 'studio');
        setTimeout(() => {
          menuButtons[curMenuButtonIndex].click();
        }, speed);
      }
    } else {
      document.querySelector(`body`).classList.remove(`no-scroll`);
      navToggleButton.focus();

      nav.classList.toggle(`Nav--visible`);
      nav.removeEventListener(`keydown`, keyHandler, {
        passive: true
      });

      setTimeout(() => {
        nav.classList.toggle(`Nav--is-active`);
      }, 1000);
      if (isHeaderLight) {
        document.body.classList.add(`Body--light`);
      }

      resetNavFilters();
      closeSubMenus();
      resetButtons();
      initialOpen = false;
    }


    navToggleButton.setAttribute(`aria-expanded`, curState);
    navToggleButton.textContent = curState ? `Close` : `Menu`;
  };

  const closeNav = function () {
    curState = false;
    navToggleButton.focus();
    document.querySelector(`body`).classList.remove(`no-scroll`);
    nav.classList.remove(`Nav--visible`);
    nav.removeEventListener(`keydown`, keyHandler, {
      passive: true
    });

    setTimeout(() => {
      nav.classList.remove(`Nav--is-active`);
    }, 100);
    navToggleButton.setAttribute(`aria-expanded`, curState);

    if (isHeaderLight) {
      document.body.classList.add(`Body--light`);
    }

    resetNavFilters();
    closeSubMenus();
    resetButtons();
    initialOpen = false;
  }




  // navLinks.forEach(item => item.addEventListener(`click`, closeNav));

  if (navToggleHome) {
    let isRun = false;
    navToggleHome.addEventListener('click', () => {
      // if(!isRun && checkWindowSize() === 'large'){
      if(!isRun){
        isRun = !isRun;
        homeHeader.animate(
          {
            top: ['43%', '0'],
          },
          {
            iterations: 1,
            duration: 500,
            fill: 'forwards',
            easing: 'ease-in-out',
          }
        );
        setTimeout(() => {
          toggleNav();
        }, 500);
      } else {
        toggleNav();
      }
    });
  } else {
    if (navToggleButton) {
      navToggleButton.addEventListener(`click`, toggleNav);
    }
  }

  menuButtons.forEach(button => {
    button.addEventListener('click', (event) => {
      resetNavFilters();
      closeSubMenus();
      resetButtons();
      const curIndex = menuButtons.indexOf(event.target);
      const verticalBar = document.querySelector(`.Nav__menu`);
      if(initialOpen){
        verticalBar.animate(
          {
            left: ['75%', '25%'],
          },
          {
            iterations: 1,
            duration: 350,
            fill: 'forwards',
            easing: 'ease-in-out',
            pseudoElement: '::after',
          }
        );
      }
      initialOpen = true;
      setTimeout(() => {
        verticalBar.animate(
          {
            left: ['25%', '75%'],
          },
          {
            iterations: 1,
            duration: 350,
            fill: 'forwards',
            easing: 'ease-in-out',
            pseudoElement: '::after',
          }
        );
      }, 500)
      if(checkWindowSize() === 'large'){
        setTimeout(() => {
          triggerMenuButton(curIndex);
        }, 700);
      } else {
        setTimeout(() => {
          triggerMenuButton(curIndex);
        }, 100);
      }
    });
  });

  function triggerMenuButton(num){
    menuButtons[num].ariaExpanded = true;
    menuButtons[num].nextElementSibling.classList.toggle(`is-active`);
    menuButtons[num].nextElementSibling.querySelector(`.Nav__close`).focus();
  }

  closeButtons.forEach(button => {
    button.addEventListener('click', () => {
      closeSubMenus();
    });
  });

  function closeSubMenus() {
    secondaryPanes.forEach(pane => {
      pane.classList.remove(`is-active`);
    });
  }

  function resetButtons() {
    menuButtons.forEach(button => {
      button.ariaExpanded = false;
    });
  }

  navHoverLinks.forEach(link => {
    link.addEventListener(`mouseover`, (el) => {
      let hoverItem = el.target.dataset.slug;
      let associatedImages = Array.from(el.target.parentNode.parentNode.parentNode.parentNode.querySelectorAll(`.Nav__tertiary-images li`))
      // console.log(associatedImages);
      associatedImages.forEach(li => {
        li.classList.remove(`is-active`);
      });
      // console.log(associatedImages);
      el.target.parentNode.parentNode.parentNode.parentNode.querySelector(`.Nav__tertiary-images li[data-slug="${hoverItem}"]`).classList.add(`is-active`)
    });
    link.addEventListener(`focus`, (el) => {
      let hoverItem = el.target.dataset.slug;
      let associatedImages = Array.from(el.target.parentNode.parentNode.parentNode.parentNode.querySelectorAll(`.Nav__tertiary-images li`))
      // console.log(associatedImages);
      associatedImages.forEach(li => {
        li.classList.remove(`is-active`);
      });
      // console.log(associatedImages);
      el.target.parentNode.parentNode.parentNode.parentNode.querySelector(`.Nav__tertiary-images li[data-slug="${hoverItem}"]`).classList.add(`is-active`)
    });
    link.addEventListener(`mouseout`, (el) => {
      // let hoverItem = el.target.dataset.slug;
      let associatedImages = Array.from(el.target.parentNode.parentNode.parentNode.parentNode.querySelectorAll(`.Nav__tertiary-images li`))
      associatedImages.forEach(li => {
        li.classList.remove(`is-active`);
      });
    });
    link.addEventListener(`click`, function(){
      closeNav();
    });
  });


  function resetNavFilters(){
    Array.from(navFilters.querySelectorAll(`button`)).map(a => a.setAttribute(`aria-selected`, `false`));
    navFilters.querySelector(`li:first-child button`).setAttribute(`aria-selected`, `true`)
    navFilterableEls[0].parentNode.classList.remove(`Nav__links--filtered`);
    navFilterableEls.map(li => li.style.display = 'block');
  }

  navFilters.addEventListener(`click`, (el) => {
    const curButton = el.target;
    const curFilter = curButton.dataset.filter;
    if(!curFilter){
      return;
    }
    Array.from(navFilters.querySelectorAll(`button`)).map(a => a.setAttribute(`aria-selected`, `false`));
    curButton.setAttribute(`aria-selected`, `true`);
    curButton.parentNode.parentNode.parentNode.nextElementSibling.classList.add(`Nav__links--filtered`);
    navFilterableEls.map(li => {
      li.style.display = li.dataset.filter.indexOf(curFilter) === -1 ? 'none' : 'block';
    });
  });

  if (navFiltersDrop) {
    navFiltersDrop.addEventListener(`change`, (el) => {
       Array.from(navFilters.querySelectorAll(`button`)).map(a => a.setAttribute(`aria-selected`, `false`));
       Array.from(navFilters.querySelectorAll(`button`)).map(a => {
        if(a.dataset.filter === el.target.value){
          a.setAttribute(`aria-selected`, `true`)
        }
      });
      navFilterableEls.map(li => {
        li.style.display = li.dataset.filter.indexOf(el.target.value) === -1 ? 'none' : 'block';
      });
    })
  }




  window.addEventListener('resize', () => {
    if(checkWindowSize() !== windowSize ){
      document.querySelector(`body`).classList.remove(`no-scroll`);
      curState = false;
      resetNavFilters();
      closeSubMenus();
      resetButtons();
      closeNav();
      navToggleButton.textContent = `Menu`;
      initialOpen = false;
    }
  });

}

// EOF
