export default function fadeMain() {
  const durationOfFade = 700;
  const documentFade = document.querySelector('main')
  // window.addEventListener('pageshow', function (event) {
  //   var historyTraversal = event.persisted ||
  //     (typeof window.performance !== 'undefined' &&
  //       window.performance.navigation.type === 2);
  //   if (historyTraversal) {
  //     // Handle page restore.
  //     window.location.reload();
  //   }
  // });
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant'
  });
  document.body.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant'
  });

  // documentFade.animate({
  //   opacity: [0, 1]
  // }, {
  //   duration: 1000,
  //   fill: 'forwards'
  // }).onfinish = function () {
  //   documentFade.style.visibility = 'visible';
  // };

  window.addEventListener('beforeunload', function () {
    documentFade.animate({
      opacity: [1, 0]
    }, {
      duration: durationOfFade,
      fill: 'forwards'
    }).onfinish = function () {
      documentFade.style.visibility = 'hidden';
    };
  }, {
    passive: true
  });
  window.addEventListener('pagehide', function () {
    documentFade.animate({
      opacity: [1, 0]
    }, {
      duration: durationOfFade,
      fill: 'forwards'
    }).onfinish = function () {
      documentFade.style.visibility = 'hidden';
    };
  }, {
    passive: true
  });
  window.addEventListener('onload', function () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
    document.body.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
    documentFade.animate({
      opacity: [0, 1]
    }, {
      duration: durationOfFade,
      fill: 'forwards'
    }).onfinish = function () {
      documentFade.style.visibility = 'visible';
    };
  }, {
    passive: true
  });
  window.addEventListener('pageshow', function () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
    document.body.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
    documentFade.animate({
      opacity: [0, 1]
    }, {
      duration: durationOfFade,
      fill: 'forwards'
    }).onfinish = function () {
      documentFade.style.visibility = 'visible';
    };
  }, {
    passive: true
  });
}
