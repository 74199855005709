export default function filter() {
  const filterSet = document.querySelector('.Projects__categories');

  const filterDrop = document.querySelector('.Projects__categories-drop');

  const filterableProjects = Array.from(document.querySelectorAll('.Projects__list a'));

  const filterButtons =  Array.from(document.querySelectorAll('.Projects__categories button'));

  if (filterSet) {
    filterSet.addEventListener(`click`, (el) => {
      const curButton = el.target;
      const curFilter = curButton.dataset.filter;
      if (!curFilter) {
        return;
      }
      filterButtons.map(a => a.setAttribute(`aria-selected`, `false`));
      curButton.setAttribute(`aria-selected`, `true`);

      if (!document.startViewTransition) {
        filterableProjects.map(project => {
          project.dataset.filter.indexOf(curFilter) === -1 ? project.setAttribute('hidden', '') : project.removeAttribute('hidden');
        });
      } else {
        document.startViewTransition(() => {
          filterableProjects.map(project => {
            project.dataset.filter.indexOf(curFilter) === -1 ? project.setAttribute('hidden', '') : project.removeAttribute('hidden');
          });
        })
      }
      // curButton.blur();
    })
  }


  if (filterDrop) {
    filterDrop.addEventListener(`change`, (el) => {
      filterButtons.map(a => a.setAttribute(`aria-selected`, `false`));
      filterButtons.map(a => {
        if(a.dataset.filter === el.target.value){
          a.setAttribute(`aria-selected`, `true`)
        }
      });
      if (!document.startViewTransition) {
        filterableProjects.map(project => {
          project.dataset.filter.indexOf(el.target.value) === -1 ? project.setAttribute('hidden', '') : project.removeAttribute('hidden');
        });
      } else {
        document.startViewTransition(() => {
          filterableProjects.map(project => {
            project.dataset.filter.indexOf(el.target.value) === -1 ? project.setAttribute('hidden', '') : project.removeAttribute('hidden');
          });
        })
      }
    })
  }
}
