import gsap from 'gsap';
import { Observer } from 'gsap/Observer';
gsap.registerPlugin(Observer);

export default function Scroller() {
  const scroller = document.querySelector('.Scroller');
  const scrollerTrack = document.querySelector('.Scroller__track');
  scrollerTrack.childNodes.forEach(function(el){
    const clone = el.cloneNode(true);
    scrollerTrack.append(clone);
  })

  const object = {
    value: 1
  };

  const createObserver = (velocityMultiplier) => {
    Observer.create({
      target: scrollerTrack,
      type: 'wheel,scroll,touch',
      onChange: (self) => {
        let v = (self.velocityY + self.velocityX) * velocityMultiplier * 0.04;
        if(isMobile()){
          v = gsap.utils.clamp(-80, 80, v);
        } else {
          v = gsap.utils.clamp(-80, 80, v);
        }
        tl.timeScale(v);
        const resting = v < 0 ? -1 : 1;
        gsap.fromTo(
          object, { value: v }, {
            value: resting,
            duration: 1,
            onUpdate: () => {
              tl.timeScale(object.value);
            }
          }
        );
      }
    });
  };

  let tl = gsap.timeline({
    repeat: -1,
    onReverseComplete: () => {
      tl.progress(1);
    }
  });

  const isMobile = () => window.innerWidth < 768;

  const updateAnimation = () => {
    if (isMobile()) {
      tl.kill();
      tl = gsap.timeline({
        repeat: -1,
        onReverseComplete: () => {
          tl.progress(1);
        }
      });
      let totalHeight = 0;
      Array.from(document.querySelectorAll('.Scroller__track > a')).forEach(function(el){
        totalHeight += el.offsetHeight;
      });
      console.log('total height', totalHeight);
      tl.fromTo('.Scroller__track',
      {
        y: 0,
        x: 0,
      },
      {
        x: 0,
        y: -totalHeight/2,
        duration: totalHeight/80,
        ease: 'none'
      });
      createObserver(isMobile() ? -1 : 1);
      console.log('isMobile',isMobile());
    } else {
      tl.kill();
      tl = gsap.timeline({
        repeat: -1,
        onReverseComplete: () => {
          tl.progress(1);
        }
      });
      let totalWidth = 0;
      Array.from(document.querySelectorAll('.Scroller__track > a')).forEach(function(el){
        totalWidth += el.offsetWidth;
      });
      // console.log('total width', totalWidth);
      tl.fromTo('.Scroller__track',
      {
        x: 0,
        y: 0,
      },
      {
        y: 0,
        x: -totalWidth/2,
        duration: totalWidth/100,
        ease: 'none'
      });
      createObserver(1);
    }
  };

  // updateAnimation();
  window.addEventListener('resize', updateAnimation);

  // Center the middle block on page load
  // window.onload = () => {
  //   scroller.classList.add('ready');
  //   // Uncomment the below for slidein
  //   // startScrolling(110)
  //   setTimeout(()=>{
  //     updateAnimation();
  //     window.addEventListener('resize', updateAnimation);
  //   }, 1000);
  // };
  // setTimeout(()=>{
    setTimeout(()=>{
      scroller.classList.add('ready');
    }, 4000)
    setTimeout(()=>{
      updateAnimation();
      window.addEventListener('resize', updateAnimation);
    }, 5900);
  // }, 3000);
}
