/**
 * Adds click event listeners to elements with the class name "Staff__member".
 * When a member is clicked, it toggles the "is-active" class on the clicked member
 * and removes the "is-active" class from all other members.
 */
export default function Staff() {
  const staffOpen = Array.from(
    document.querySelectorAll('.Staff__member figure')
  );
  const staffClose = Array.from(
    document.querySelectorAll('.Staff__member .Staff__close')
  );

  staffOpen.forEach((member) => {
    member.addEventListener('click', (event) => {
      const clickedMember = event.currentTarget.parentNode;

      staffOpen.forEach(member => {
        // console.log('member', member);
        // console.log('clickedMember', clickedMember);
        if (member.parentNode !== clickedMember) {
          member.parentNode.classList.remove('is-active');
        }
      });

      clickedMember.classList.toggle('is-active');

      setTimeout(() => {
        // console.log('scrolling');
        if (clickedMember.classList.contains('is-active')) {
          clickedMember.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
        }
      }, 510);
    });
  });
  staffClose.forEach((member) => {
    member.addEventListener('click', (event) => {
      const clickedMember = event.currentTarget.parentNode.parentNode;
      clickedMember.classList.remove('is-active');
    });
  });
}

// EOF
